/* You can add global styles to this file, and also import other style files */
//tailwindcss
@tailwind base;
@tailwind components;
@tailwind utilities;

//TYPOGRAPHY
//Font CDN Import
@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.woff2') format('woff2'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.woff2') format('woff2'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.woff2') format('woff2'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.woff2') format('woff2'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.woff2') format('woff2'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.woff2') format('woff2'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.woff2') format('woff2'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.woff2') format('woff2'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.woff2') format('woff2'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.woff2') format('woff2'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra Display';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot?#iefix') format('embedded-opentype'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.woff') format('woff'), url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.ttf') format('truetype');
  font-style: normal;
}
body {
  font-family: 'Fedra', sans-serif;
  font-weight: 400;
}

@layer base {
  input {
    @apply border-2 border-solid border-secondary rounded p-2 w-full focus:outline-none focus:border-primary;
  }
  button {
    @apply text-white bg-gradient-to-r from-turquoise-dark to-turquoise-bright hover:bg-gradient-to-br focus:ring-2 focus:outline-none font-medium rounded-full text-lg px-5 py-2 text-center my-4 disabled:bg-none disabled:bg-grey-concrete;
  }
}

